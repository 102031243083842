import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
// import {
// 	AiOutlineGithub,
// 	AiFillInstagram,
// 	AiOutlineTwitter,
// } from "react-icons/ai";
import {BsFacebook} from "react-icons/bs";
import {TbLetterV} from "react-icons/tb";
import {RiLinkedinFill} from "react-icons/ri";
// import {FaTelegramPlane} from "react-icons/fa";
// import {useMediaQuery} from "../../hooks/UseMediaQuery";
import hero from "../../assets/images/Hero.png";
// import {Mandala} from "../Mandala";
import TextFlipper from "../TextFlipper";

const socialLinks = [
	{
		title: "LinkedIn",
		link: "https://www.linkedin.com/in/buddika-gunawardena",
		icon: <RiLinkedinFill/>
	},
	{
		title: "Facebook",
		link: "https://www.facebook.com/BSGunawardena",
		icon: <BsFacebook />
	},
	{
		title: "Вконтакт",
		link: "https://vk.com/gunawardena",
		icon: <TbLetterV/>
	},
	// {
	// 	https://twitter.com/BuddikaDeBoris
	// 	https://www.instagram.com/buddikagunawardena
	// }
]

const specialties = [
	{id: 1, name: "Node API"},
	// {id: 2, name: "Back-end"},
	{id: 3, name: "React"},
	{id: 4, name: "Next JS 13"},
	// {id: 4, name: "Android"},
	{id: 5, name: "IoT"},
	// {id: 6, name: "Decentralized Exchanges"},
	{id: 6, name: "MongoDB"},
	{id: 7, name: "MySQL"},
	{id: 8, name: "Linux"},
	// {id: 8, name: "Network admin"},
	// {id: 9, name: "Database"},
	// {id: 10, name: "Photography"},
];

const Hero = () => {
	// const isSmall = useMediaQuery("(max-width: 1024px)"); // use standard pixel widths
	const [fromMiddle, setFromMiddle] = useState<number>(0);
	const [visible, setVisible] = useState<boolean>();
	// const [glowId, setGlowId] = useState<number>(0);
	const timeout: number = 1000;

	const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

	function onHeroClick() {
		if (fromMiddle !== 400) {
			setFromMiddle(400);
			// setVisible(true);
		}
		// else {
		// 	setVisible(false);
		// 	setFromMiddle(0);
		// }
	}

	// function randomInt(min: number, max: number): void {
	// 	setInterval(() => {
	// 		setGlowId(Math.floor(Math.random() * (max - min + 1) + min));
	// 	}, timeout);
	// }

	function generatePattern(сколько: number): Array<JSX.Element> {
		// randomInt(1, сколько);

		let pattern: Array<JSX.Element> = [];
		for (let i = 0; i < сколько; i++) {
			pattern.push(
				<div
					key={i}
					className="w-[1px] h-[1px] rounded-full transition-colors duration-200 bg-gray-600"
				/>
				// <div
				// 	key={i}
				// 	className={`w-[1px] h-[1px] rounded-full transition-colors duration-200 ${
				// 		glowId === i
				// 			? "bg-white scale-big shadow-lg shadow-white"
				// 			: "bg-gray-600"
				// 	}`}
				// />
			);
		}

		return pattern;
	}
	// randomInt(1, 96);

	useEffect(() => {
		const img = new Image();
		img.src = hero;
		img.addEventListener("load", () => {
			setIsImageLoaded(true);
			setVisible(true);
		});
	}, []);

	useEffect(() => {
		setTimeout(() => {
			onHeroClick();
		}, timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="relative w-screen h-screen flex overflow-clip">
			{/* <div className="absolute bottom-0 left-0 w-full h-[150px] bg-dots mix-blend-lighten z-10 opacity-20">
			</div> */}
			{isImageLoaded ? (
				<div className="relative grid grid-cols-2 grid-rows-3 w-full h-full">
					<section>
						{visible && (
							<section className="ml-8 md:ml-16 lg:ml-24 mt-10 md:mt-24 z-10">
								<motion.div
									initial={{opacity: 0, scale: 0}}
									animate={{opacity: 1, scale: 1}}
									transition={heroText}
									className="text-start text-white"
								>
									<h3 className="text-5xl font-sans">Hi</h3>
								</motion.div>
								<div className="flex flex-row gap-3">
									<motion.div
										className="text-xl text-white"
										initial={{opacity: 0, translateX: -200}}
										animate={{opacity: 1, translateX: 0}}
										transition={ease}
									>
										I'm
									</motion.div>
									<motion.div
										className="text-xl md:text-4xl font-poppins tracking-normal md:tracking-wider"
										initial={{opacity: 0, translateY: -700}}
										animate={{opacity: 1, translateY: 0}}
										transition={easeName}
									>
										<span className="gradient-text font-helvetica text-[1.5rem] sm:text-[2.5rem]">
											Buddika Gunawardena
										</span>
									</motion.div>
								</div>
								<motion.div
									initial={{opacity: 0}}
									animate={{opacity: 1}}
									transition={opacityTitle}
									className="relative ml-3 md:ml-16 lg:ml-24 w-full flex flex-col items-end text-white text-lg md:text-3xl"
								>
									<div className="absolute top-0 left-0 w-[140px] lg:w-full h-[150px] grid grid-cols-12 grid-flow-row gap-0 overflow-clip z-0">
										{generatePattern(96)}
										{/* <div className="absolute top-0 left-0 w-full h-full bg-dots opacity-0" /> */}
										<div id="stars" />
										<div id="stars" />
										<div id="stars2" />
										<div id="stars" />
										<div id="stars2" />
										<div id="stars" />
										<div id="stars2" />
									</div>
									<div className="absolute top-0 left-[140px] w-[140px] lg:w-[320px] h-[150px] grid grid-cols-12 grid-flow-row gap-0 overflow-clip z-0">
										{generatePattern(96)}
									</div>
									<div className="relative ml-0 w-full flex flex-col items-end text-white text-lg md:text-3xl">
										<div className="absolute top-0 left-0 w-[140px] lg:w-[320px] h-[150px] grid grid-cols-12 grid-flow-row gap-0 overflow-clip z-0">
											<div id="stars" />
											<div id="stars2" />
										</div>
										<div className="absolute top-0 right-0 w-[140px] lg:w-[320px] h-[150px] grid grid-cols-12 grid-flow-row gap-0 overflow-clip z-0">
											<div id="stars" />
											<div id="stars2" />
										</div>
									</div>
									<div className="font-inter ml-auto">
										<div>Web Developer <span className="text-3xl gradient-text opacity-70">
												&
											</span></div>
											<div>Linux&nbsp;SysAdmin</div>

										{/* <div className="relative flex flex-row gap-3 items-end">
											<span className="absolute top-2 right-7 text-3xl gradient-text opacity-70">
												&
											</span>
											<span>Linux&nbsp;SysAdmin</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
											<span>&nbsp;</span>
										</div> */}
										{/* <div>Photographer.</div> */}
									</div>
								</motion.div>
							</section>
						)}
					</section>

					<section className="-z-10 ">
						<div className="w-full overflow-clip">
							<motion.img
								src={hero}
								alt="hero"
								className="mx-auto w-full object-contain max-w-[300px]"
								initial={{ opacity: 0 }}
								animate={{
									opacity: 1, transition: {
										delay:2
								}}}
								// layout
								// transition={heroImage}
								// animate={
								// 	isSmall ? {scale: 0.7, x: 90, y: 100} : {x: fromMiddle}
								// }
							/>
						</div>
					</section>

					<section className="row-start-3 ml-8 md:ml-16 lg:ml-24">
						<motion.div
							initial={{scale: 0}}
							animate={{
								scale: 1, transition: {
									delay: 3,
									delayChildren: 0.5
							}}}
							transition={easeSocials}
							className="z-10"
						>
							<section className="mt-10 md:mt-16 w-fit">
								<p className="text-lg font-thin">Let's get in touch</p>
								<motion.ul
									className="mt-4 text-2xl flex flex-row gap-3 items-center text-neutral-400"
									variants={socials}
									initial="hidden"
									animate="visible"
									>
										{socialLinks.map((social) => (
											<motion.li key={social.title} variants={socialItems} className="transition-colors duration-300 hover:text-white">
											<a
												href={social.link}
												target="_blank"
												rel="noreferrer"
											>
												{social.icon}
											</a>
										</motion.li>
										))}
								</motion.ul>
							</section>
						</motion.div>
					</section>
				</div>
			) : (
				<div className="w-screen h-screen flex">
					<div className="mx-auto my-auto">
						<TextFlipper
							className="font-potra text-white text-5xl"
							dataValue="Loading..."
							text="XXXXXXX..."
							onHover={false}
						/>
					</div>
				</div>
			)}

			<div className="absolute bottom-6 w-full overflow-hidden">
				<section className="flex flex-row gap-6">
					<div className="flex flex-row gap-6 font-leaner font-bold text-neutral-400 marquee">
						{specialties.map((specialty) => (
							<motion.div
								key={specialty.id}
								className="flex flex-row gap-2 items-center whitespace-nowrap"
								initial={{opacity: 0, translateX: 2000}}
								animate={{opacity: 1, translateX: 0}}
								transition={{
									duration: 0.3,
									delay: 2 + specialty.id * 0.2,
								}}
							>
								<div className="flex flex-row gap-1">
									<span className="w-[1px] h-[20px] transform -skew-x-12 bg-white" />
									<span className="w-[1px] h-[20px] transform -skew-x-12 bg-white" />
								</div>
								<span>{specialty.name}</span>
							</motion.div>
						))}
					</div>
				</section>
			</div>
		</div>
	);
};

// const spring = {
// 	type: "spring",
// 	stiffness: 700,
// 	damping: 30,
// };
const ease = {
	type: "spring",
	stiffness: 100,
	duration: 3,
	delay: 1.5,
};
const easeName = {
	type: "spring",
	stiffness: 100,
	damping: 20,
	duration: 3,
	delay: 1.6,
};
const easeSocials = {
	type: "spring",
	stiffness: 100,
	damping: 20,
	duration: 3,
	delay: 2,
};
const opacityTitle = {
	ease: [0.6, 0.82, 0.165, 1],
	duration: 2.5,
	delay: 2,
};
// Transition properties of specialties
// delay: dynamically calculated
// duration: 0.5

// const heroImage = {
// 	ease: [0.6, 0.82, 0.165, 1],
// 	// ease: [0.6, 0.01, -0.05, 0.95],
// 	// ease: "anticipate",
// 	duration: 3,
// };

const heroText = {
	ease: "anticipate",
	duration: 1.5,
};

const socials = {
	hidden: { opacity: 0 },
	visible: {
		opacity: 1,
		transition: {
			delayChildren: 3.5,
			staggerChildren: 0.2,
		},
	}
}

const socialItems = {
	hidden: { opacity: 0 },
	visible: {opacity: 1 }
}

export default Hero;
