import "./App.css";
import Hero from "./uiComponents/hero/Hero";
// import Letter from "./uiComponents/Letter";

function App() {
    return <div className="App">
        <Hero />
        {/* <Letter /> */}
    </div>;
}

export default App;
