import React, {useRef, useState, useEffect} from "react";

const letters = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
	"a",
	"b",
	"c",
	"d",
	"e",
	"f",
	"g",
	"h",
	"i",
	"j",
	"k",
	"l",
	"m",
	"n",
	"o",
	"p",
	"q",
	"r",
	"s",
	"t",
	"u",
	"v",
	"w",
	"x",
	"y",
	"z",
];

const TextFlipper = ({dataValue, text, className, onHover}) => {
	const [txt, setTxt] = useState(text);
	const flipperRef = useRef();
	// console.log("refff", flipperRef.current?.getAttribute("data-value").length);

	function flip() {
		let iterations = 0;
		const interval = setInterval(() => {
			setTxt(
				flipperRef.current
					?.getAttribute("data-value")
					.split("")
					.map((letter, index) => {
						if (index < iterations) {
							return flipperRef.current?.getAttribute("data-value")[index];
						}
						return letters[Math.floor(Math.random() * 26)];
					})
					.join("")
			);

			if (iterations >= flipperRef.current?.getAttribute("data-value").length) {
				clearInterval(interval);
			}

			// if text is shorter than 6 letters, we want the duration to be more
			if (flipperRef.current?.getAttribute("data-value").length <= 12) {
				iterations += 1 / 2;
			} else {
				iterations += 1;
			}
		}, 30);
	}

	useEffect(() => {
		!onHover &&
			setInterval(() => {
				flip();
			}, 2500);

		return () => {
			clearInterval();
		};
	}, []);

	return (
		<div
			ref={flipperRef}
			className={`flipper ${className}`}
			data-value={dataValue}
			onMouseEnter={onHover && flip}
		>
			{txt}
		</div>
	);
};

export default TextFlipper;
